@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.categoryTitleBlock {
  width: 100%;
  color: white;
  margin-bottom: 40px;

  background: linear-gradient(90.01deg, #004561 48.61%, #005B80 74.04%, #004561 99.99%);

  @media (--viewportLarge) {
    margin-left: auto;
    margin-right: auto;
  }
}

.categoryTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;

  margin-bottom: 20px;
  margin-top: 20px;

  @media --viewportMedium {
    margin-top: 140px;
  }
}

.categoryDescription {
  width: calc(100% - 48px);
  max-width: 1000px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  margin-bottom: 40px;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportMedium) {
    margin-left: auto;
    margin-right: auto;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 100%;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.categoriesBlock {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.categoryHeader {
  display: block;
  width: 100%;
  white-space: nowrap;

  @media (--viewportMedium) {
    width: auto;
    margin: 0 30px 0 0;
  }
}

.showAllHeader {
  display: block;
  width: 100%;
  white-space: nowrap;
  color: #0AB4F3;
  font-weight: 700;

  @media (--viewportMedium) {
    width: auto;
    margin: 0 30px 0 0;
  }
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
    width: 100%;
    margin-left: auto;
    max-width: 1226px;
    margin-right: auto;
  }
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
  padding-left: 24px;

  @media (--viewportMedium) {
    padding-left: 36px;
  }
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
  padding-left: 24px;

  @media (--viewportMedium) {
    padding-left: 36px;
  }

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.mapPanel {
  visibility: hidden;

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: none;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
